import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";

function Footer() {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const contactData =
    getFocus(_project, "ContactProperties")?.[pin]?.[pid] ?? [];

  return (
    <div>
      {/* <Box
        component="img"
        src={`${STATICS}kista.jpg`}
        sx={{ width: "100%", height: { lg: 504, xs: 260 }, objectFit: "cover" }}
      /> */}

      <Box
        sx={{
          mt: 5,
          pb: 55,
          px: { md: 5, xs: 4 },
          py: { md: 0, xs: 2 },
          lineHeight: 1.4,
        }}
      >
        <Box
          sx={{
            mb: 2,
          }}
        >
          <span className="h3">
            {" "}
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "SidefodKontaktOverskrift"
              )[0]?.Label
            }
          </span>
        </Box>

        {contactData?.map((item, i) => {
          return (
            <Grid
              container
              sx={{ mb: 5 }}
              spacing={{ xs: 3, lg: 0 }} // Remove spacing for desktop (lg)
              key={i}
            >
              <Grid item lg={6} xs={12}>
                <Box>
                  <span className="h4"> {item?.Name}</span> <br />
                  
                  {/* Conditionally render address lines */}
                  {item?.AddressLine1 && (
                    <>
                      <span className="paragraph1-light">
                        {item?.AddressLine1}
                      </span>
                      <br />
                    </>
                  )}
                  {item?.AddressLine2 && (
                    <>
                      <span className="paragraph1-light">
                        {item?.AddressLine2}
                      </span>
                      <br />
                    </>
                  )}

                  <span
                    className="paragraph1-light"
                    style={{
                      textDecoration: "underline",
                      color: clr.primary_text,
                    }}
                  >
                    {item?.Phone}
                  </span>
                  <br />
                  <Typography
                    style={{ color: clr.primary_text }}
                    component="a"
                    href={`mailto: ${item.Email}`}
                  >
                    <span className="paragraph1-light">{item?.Email}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                lg={6}
                xs={12}
                sx={{
                  textAlign: { lg: "center", xs: "left" },
                  mt: { lg: -3, xs: 0 }
                }}
              >
                <Box sx={{ maxWidth: { xs: "180px", lg: "180px" } }}>
                  {/* <Box component="img" src={`${STATICS}${item?.ImageUrl}`} /> */}
                  <Box
                    component="img"
                    src="/Trekanten/logos/logo.png"
                    alt="footerimage"
                  />
                </Box>
              </Grid>
            </Grid>
          );
        })}

        <div style={{ height: 50 }} />
      </Box>
    </div>
  );
}

export default Footer;
